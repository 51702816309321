





































































































import { computed, defineComponent, ref } from '@vue/composition-api';
import { useUserActions, useUserGetters } from '@/store';
import { ObjectId } from 'bson';

export default defineComponent({
  name: 'MDiscordConnection',
  props: {
    doc: {
      required: true,
      type: Object
    },
    isSkippable: {
      required: true,
      type: Boolean,
      default: false
    },
    programId: {
      required: true,
      type: String,
      default: ''
    },
    adkIndex: {
      required: true,
      type: Number,
      default: 0
    },
    userType: {
      required: true,
      type: String,
      default: ''
    },
    adkType: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    createAccountLabel: {
      type: String,
      default: ''
    },
    existingAccountLabel: {
      type: String,
      default: ''
    }
  },
  setup(props: any, ctx: any) {
    const { updateAdkData } = useUserActions(['updateAdkData']);
    const { getProgramDoc } = useUserGetters(['getProgramDoc']);
    const DISCORD_AUTH = `https://discord.com/api/oauth2/authorize?response_type=code&client_id=${process.env.VUE_APP_DISCORD_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_DISCORD_REDIRECT}&response_type=code&scope=identify%20guilds.join`;
    const DISCORD_REGISTER = `https://discord.com/register?redirect_to=%2Foauth2%2Fauthorize%3Fclient_id%3D${process.env.VUE_APP_DISCORD_CLIENT_ID}%26redirect_uri=${process.env.VUE_APP_DISCORD_REDIRECT}%26response_type%3Dcode%26scope%3Didentify%2520guilds.join%2520gdm.join%2520email`;
    const document = computed({
      get: () => props.doc,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    async function saveProgramId() {
      localStorage.setItem('programId', props.programId);
      localStorage.setItem('adkIndexValue', props.adkIndex);
      localStorage.setItem('userType', props.userType);
    }
    const isSkipDiscard = ref(false);
    const showSkipConfirmation = ref(false);
    async function skipDiscord() {
      isSkipDiscard.value = true;
      let documentType = 'Student';
      if (props.userType === 'organizer') {
        documentType = 'Program';
      }
      document.value.data.adks[props.adkIndex].discordIntegraionSkipped = true;
      document.value.data.adks[props.adkIndex].completed = new Date();
      await updateAdkData({
        program_id: document.value.data._id.toString(),
        document_type: documentType,
        data: document.value.data.adks[props.adkIndex]
      });
      document.value.data = {
        adks: [],
        ...getProgramDoc.value
      };
      setTimeout(() => {
        isSkipDiscard.value = false;
      }, 1000);
      showSkipConfirmation.value = false;
    }

    return {
      document,
      saveProgramId,
      isSkipDiscard,
      skipDiscord,
      showSkipConfirmation,
      DISCORD_AUTH,
      DISCORD_REGISTER
    };
  }
});
